import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import format from 'date-fns/format'

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  static getFileName = (response: HttpResponse<Blob>) => {
    let filename = 'inventura.excel'
    try {
      const contentDisposition = response.headers.get('content-disposition')
      if (contentDisposition) {
        const r = /(?:filename=")(.+)(?:")/
        const regex = r.exec(contentDisposition)
        if (regex?.length) filename = (r.exec(contentDisposition) as RegExpExecArray)[1]
      }
    } catch (e) {
      console.warn(e)
    }
    return decodeURIComponent(filename)
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  static downLoadFile = (response: HttpResponse<Blob>) => {
    if (response.body) {
      const filename: string = UtilsService.getFileName(response)
      const binaryData = []
      binaryData.push(response.body)
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }))
      downloadLink.setAttribute('download', filename)
      document.body.appendChild(downloadLink)
      downloadLink.click()
      downloadLink.remove()
    }
  }

  static downloadFile(buffer: ArrayBuffer | undefined, fileName: string, type?: string): void {
    if (buffer) {
      const data: Blob = new Blob([buffer], { type });
      const fileURL = window.URL.createObjectURL(data);
      const anchor = document.createElement('a');
      anchor.href = fileURL;
      anchor.download = `${fileName}`;
      anchor.click();
      window.URL.revokeObjectURL(fileURL);
    }
  }

  static loadFileData = (response: HttpResponse<Blob>): File | undefined => {
    if (response.body) {
      const filename: string = UtilsService.getFileName(response)
      const binaryData = []
      binaryData.push(response.body)
      return new File(binaryData, filename)
    }
    return undefined
  }

  static safeParamUrl = (param: string | number) => encodeURIComponent(param.toString().replace(/\//g, '--'))

  static queryString = (condition: boolean, str: string) => (condition ? str : '')

  static formattedNumber = (x: number, digits = 2) =>
    x.toFixed(digits).replace(new RegExp(`[.,]${[...Array(digits)].map(() => 0).join('')}$`), '')

  static stripDiacritics = (str: string) => {
    !str?.trim().length
      ? ''
      : str
        .replace(/(e|ě|é)/gim, '(e|ě|é)')
        .replace(/(s|š)/gim, '(s|š)')
        .replace(/(c|č)/gim, '(cč)')
        .replace(/(n|ň)/gim, '(nň)')
        .replace(/(r|ř)/gim, '(rř)')
        .replace(/(z|ž)/gim, '(zž)')
        .replace(/(y|ý)/gim, '(yý)')
        .replace(/(a|á)/gim, '(aá)')
        .replace(/(i|í)/gim, '(ií)')
        .replace(/(d|ď)/gim, '(dď)')
        .replace(/(u|ů|ú)/gim, '(uůú)')
        .replace(/(t|ť)/gim, '(tť)')
  }

  iconIsString = (icon: string | IconDefinition) => typeof icon === 'string'

  formatDate(date: string, dateFormat?: string) {
    try {
      return format(new Date(date), dateFormat || 'dd.MM.yyyy HH:mm')
    } catch {
      return ''
    }
  }
}
